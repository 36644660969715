import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "../components/loader";
import Packages from "../pages/packages";
const MainLayout = React.lazy(() => import("../layout"));
const Home = React.lazy(() => import("../pages/home"));
const Contact = React.lazy(() => import("../pages/contact"));
const About = React.lazy(() => import("../pages/about"));
const Services = React.lazy(() => import("../pages/services"));
const ServiceDetails = React.lazy(() => import("../pages/serviceDetails"));

export default function Routes() {
  const element = useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          element: (
            <React.Suspense fallback={<Loader/>}>
              <Home />
            </React.Suspense>
          ),
          index: true,
        },
        {
          path: "contact",
          element: (
            <React.Suspense fallback={<Loader/>}>
              <Contact />
            </React.Suspense>
          ),
        },
        {
          path: "about-us",
          element: (
            <React.Suspense fallback={<Loader/>}>
              <About />
            </React.Suspense>
          ),
        },
        {
          path: "packages",
          element: (
            <React.Suspense fallback={<Loader/>}>
              <Packages />
            </React.Suspense>
          ),
        },
        {
          path: "services",
          element: (
            <React.Suspense fallback={<Loader/>}>
              <Services />
            </React.Suspense>
          ),
        },
        {
          path: "services-details/:id",
          element: (
            <React.Suspense fallback={<Loader/>}>
              <ServiceDetails />
            </React.Suspense>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to={"/"} /> },
  ]);

  return element;
}
