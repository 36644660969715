import React from 'react'

import PackagesComp from '../components/packages'
import ChannelBrands from '../components/channelBrands'
import Hero from '../components/hero'

export default function Packages() {
    return (
        <main className="site-main">
            {/* page-header */}
            <Hero title='Packages' bgClass='page-header-bg'/>
            <PackagesComp />
            <ChannelBrands />
        </main>
    )
}
