import React from 'react'
import { Link } from 'react-router-dom'
import { MAIN } from '../Router/paths'

interface HeroInterface {
    title: string
    bgClass: string
}

export default function Hero({ title, bgClass }: HeroInterface) {
    return (
        <section className="page-header">
            <div className={bgClass} />
            <div className="container">
                {/* page-header-inner */}
                <div className="page-header-inner text-center">
                    <h2 className="wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="100ms">{title}</h2>
                    <ul className="page-header-inner-title list-unstyled wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                        <li className="arrow-right"><Link to={MAIN.home}>Home</Link></li>
                        <li>
                            <h4>{title}</h4>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
